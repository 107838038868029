import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import GoogleAdsense from '../components/google-adsense';
import Layout from '../components/layout';
import SEO from '../components/seo';
import getFirebase from '../utils/js/firebase';
import { getFullLocale } from '../utils/js/locale-utils';
import { checkAnswer } from '../utils/js/security-question';

const ContactPage = ({ intl }) => {
  const [values, setValues] = useState({
    email: '', subject: '', message: '', security: '',
  });
  const [errors, setErrors] = useState({
    email: false, message: false, security: false,
  });
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const firebase = getFirebase();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: false });
    setMessageSent(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const err = {
      email: !values.email,
      message: !values.message,
      security: !checkAnswer(values.security),
    };

    setErrors((prevErrors) => ({ ...prevErrors, ...err }));

    if (!err.name && !err.message && !err.security) {
      firebase.firestore().collection('mails').add({
        to: 'contact@mesetiquettes.com',
        from: values.email,
        replyTo: values.email,
        message: {
          subject: values.subject,
          text: values.message,
        },
        createdAt: new Date(),
      }).then(() => {
        setLoading(false);
        setValues({
          email: '', subject: '', message: '', security: '',
        });
        setMessageSent(true);
      });
    } else {
      setLoading(false);
    }
  };

  function errorInfo(showError, errorId) {
    return showError ? (
      <p className="help is-danger">
        <FormattedMessage id={errorId} />
      </p>
    ) : '';
  }

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'contact.title' })} />
      <section className="container section">
        <h1 className="title is-4">
          <FormattedMessage id="contact.title" />
        </h1>
        <p>
          <FormattedMessage id="contact.intro" />
        </p>
      </section>
      <section className="hero is-light">
        <div className="hero-body ">
          <div className="container section">
            <div className="columns">
              <div className="column is-half">
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="contact.form.email" />
                    </label>
                    <div className="control has-icons-left">
                      <input className={`input ${errors.email ? 'is-danger' : ''}`} type="email" name="email" onChange={handleInputChange} value={values.email} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope" />
                      </span>
                    </div>
                    {errorInfo(errors.email, 'contact.form.errors.email')}
                  </div>
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="contact.form.subject" />
                    </label>
                    <div className="control has-icons-left">
                      <input className={`input ${errors.subject ? 'is-danger' : ''}`} type="text" name="subject" onChange={handleInputChange} value={values.subject} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope-open" />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="contact.form.message" />
                    </label>
                    <div className="control">
                      <textarea className={`textarea ${errors.message ? 'is-danger' : ''}`} name="message" onChange={handleInputChange} value={values.message} />
                    </div>
                    {errorInfo(errors.message, 'contact.form.errors.message')}
                  </div>
                  <div className="field">
                    <label className="label">
                      <FormattedMessage id="contact.form.month" />
                    </label>
                    <div className="control has-icons-left">
                      <input className={`input ${errors.security ? 'is-danger' : ''}`} type="text" name="security" onChange={handleInputChange} value={values.security} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-robot" />
                      </span>
                    </div>
                    <p className="help">
                      <FormattedMessage id="contact.form.security" />
                    </p>
                    {errorInfo(errors.security, 'contact.form.errors.security')}
                  </div>
                  <div className="field is-grouped">
                    <div className="control">
                      <div className="columns is-vcentered">
                        <div className="column">
                          <button type="submit" className={`button is-link ${loading ? 'is-loading' : ''}`}>
                            <FormattedMessage id="contact.form.send" />
                          </button>
                        </div>
                        <div className="column">
                          { messageSent ? (
                            <p className="help is-success is-size-6">
                              <FormattedMessage id="contact.form.success" />
                            </p>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="column is-half has-text-centered">
                <div className="m-t-lg has-text-centered" style={{ maxHeight: '320px' }}>
                  <GoogleAdsense
                    style={{ display: 'block' }}
                    client="ca-pub-1569243764016253"
                    slot="6913582508"
                    format="auto"
                    responsive="true"
                  />
                </div>
                <div className="m-t-lg" style={{ maxHeight: '300px' }}>
                  <FacebookProvider appId="4453936961347739" language={getFullLocale(process.env.GATSBY_LANG)}>
                    <Page href="https://www.facebook.com/MesEtiquettes/" tabs="" showFacepile />
                  </FacebookProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

ContactPage.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(ContactPage);
